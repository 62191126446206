import { ReactNode, createContext, useContext } from 'react'
import { Service } from 'generated/restapi-sc'
import { useQGrantToken } from 'common/hooks/use-qgrant-token'
import { useServiceController } from 'modules/service/hooks/use-service-controller'
import {
  TEnrolments,
  useEnrolmentsController,
} from 'modules/enrolments/hooks/use-enrolments-controller'

interface IServiceContextProps {
  service?: Service
  isServiceLoading?: boolean
  qgrantsAccessToken?: string
  enrolments?: TEnrolments
}

const ServiceContext = createContext({} as IServiceContextProps)

export const ServiceContextProvider = (props: { children: ReactNode }) => {
  const { service, isLoading: isServiceLoading } = useServiceController()
  const { accessToken: qgrantsAccessToken } = useQGrantToken({
    appKey: service?.srv_config?.qgrant_app_key,
    appSecret: service?.srv_config?.qgrant_app_secret,
  })
  const { enrolments } = useEnrolmentsController({ srvId: service?.id })

  return (
    <ServiceContext.Provider
      value={{ service, isServiceLoading, qgrantsAccessToken, enrolments }}
    >
      {props.children}
    </ServiceContext.Provider>
  )
}

export const useServiceContext = () => useContext(ServiceContext)
