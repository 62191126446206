import { useQuery } from '@tanstack/react-query'
import {
  GetSmartCentralChildEnrolmentsQuery,
  getSdk,
} from 'generated/graphql/schema'
import { useGqlController } from 'common/hooks/use-gql-controller'
import { emitError } from 'common/utils/event-emitter'
import { addYears, format, subYears } from 'date-fns'
import { TZ_DATE } from 'common/utils/date-utils'
import { useMemo } from 'react'

interface IControllerProps {
  srvId?: number
}

export const useEnrolmentsController = (props: IControllerProps) => {
  const { gqlClient, isReady } = useGqlController()
  const { getSmartCentralChildEnrolments } = getSdk(gqlClient)

  const { data: dataEnrolments, isLoading: isEnrolmentsLoading } = useQuery(
    ['fetch-sc-enrolments', props.srvId],
    () =>
      getSmartCentralChildEnrolments({
        srvId: props.srvId!.toString(),
        startDate: format(subYears(new Date(), 1), TZ_DATE),
        endDate: format(addYears(new Date(), 1), TZ_DATE),
      }),
    {
      enabled: isReady && !!props.srvId,
      onError: (err) => {
        let errMessage = err
        emitError(`Error fetching enrolments details. ${errMessage}`)
      },
    }
  )

  const enrolments = useMemo(() => {
    return dataEnrolments?.service.enrolments.filter(
      (enrolment, idx, enrolments) =>
        enrolments.findIndex((enr) => enr.childId === enrolment.childId) === idx
    )
  }, [dataEnrolments])

  return { enrolments: enrolments }
}

export type TEnrolmentsController = ReturnType<typeof useEnrolmentsController>
export type TEnrolments =
  GetSmartCentralChildEnrolmentsQuery['service']['enrolments']
