import { useMemo } from 'react'
import { useSession } from 'next-auth/react'
import { GraphQLClient } from 'graphql-request'

type TUserMode = 'staff' | 'parent'

export const useGQLClient = (userMode: TUserMode = 'staff') => {
  const { data: session, status: sessionStatus } = useSession()

  const accessToken = useMemo(() => {
    return session && sessionStatus === 'authenticated'
      ? session.accessToken
      : ''
  }, [session, sessionStatus])

  const gqlClient = useMemo(
    () =>
      new GraphQLClient(process.env.NEXT_PUBLIC_BASE_URL + `/graphql`, {
        headers: {
          authorization: `Bearer ${accessToken}`,
          'SC-USER-MODE': userMode,
        },
      }),
    [accessToken, userMode]
  )

  return { gqlClient, isClientReady: Boolean(accessToken) }
}
